// show age consent on lianhonghong subsite

$(function() {
  if (!document.body || !document.body.classList || !document.body.classList.contains('s-lianhonghong')) return; // on lianhong

  if (navigator.userAgent && navigator.userAgent.match(/bot|lighthouse/i)) return; // don't block bot

  var bypassAgeConsent = document.cookie.replace(/(?:(?:^|.*;\s*)bypassconsent\s*=\s*([^;]*).*$)|^.*$/, "$1") || null;
  if (bypassAgeConsent) return;

  var ageConsent = '' +
    '<div id="age-consent-ui" class="lightbox at-general">' +
      '<p class="ttl">嗨！親愛的貓女貓男們，在你開始體驗臉紅好感之前……</p>' +
      '<p>' +
        '臉紅紅網站中同時包含了認識身體與認識情慾的資訊，為符合台灣的法規要求，如果你已經滿 18 歲，歡迎你時常回來；' +
        '如果你尚未滿 18 歲，我們也為你準備了輕簡幽默又易懂身體教育資訊。' +
        '接著來選擇你的年齡吧！' +
      '</p>' +
      '<div class="choices">' +
        '<button class="choiceButton">' +
          '我已滿 18 歲，馬上進入臉紅紅' +
        '</button>' +
        '<a class="choiceButton" href="https://womany.net/read/article/13619">' +
          '我未滿 18 歲，先去看身體教育' +
        '</a>' +
      '</div>' +
    '</div>';

  var $ageConsent = $(ageConsent).appendTo('body')
    .on('click', 'button.choiceButton', function() {
      $ageConsent.remove();
      var Dt = new Date(Date.now() + (86400 * 1000 * 180));
      document.cookie = "bypassconsent=true; path=/; samesite=Lax; expires=" + Dt.toUTCString();
    });
});
