/*
  wDialog.alert(content, title (opt), button_text (opt))
  wDialog.confirm(content, title (opt), button_text (opt))
    .done(true/false)
*/

var wDialog = {
  default: {
    text: '噢，可能哪裡發生錯誤了，我們正在努力解決喔！',
    title: '貼心小提醒',
    button_text: '確 定',
    cancel_text: '取 消'
  },

  alert: function(alert_text, alert_title, alert_button_text) {
    var me = this;
    var dfd = $.Deferred();

    var $dialog = $(require('../../templates/shared/dialog')({
      text:  alert_text || me.default.text,
      title: alert_title || me.default.title,
      button_text: alert_button_text || me.default.button_text
    }));

    $dialog.on('click', '#wDialog__confirm_button, .wDialog__close_btn', function(){
      $dialog.remove();
      dfd.resolve();
    })

    var $doc = $(document);
    $doc.on('keyup.shortcut', function(e){
      switch (e && e.keyCode) {
        case 13: // press enter
        case 27: // press esc
          $dialog.remove();
          dfd.resolve();
          $doc.off('keyup.shortcut');
          break;
        default:
          break;
      }
    });

    $('body').append($dialog)
    return dfd.promise();
  },

  confirm: function(confirm_text, confirm_title, confirm_button_text, confirm_cancel_text){
    var me = this;
    var dfd = $.Deferred();
    var $dialog = $(require('../../templates/shared/dialog')({
      text: confirm_text || me.default.text,
      title: confirm_title || me.default.title,
      button_text: confirm_button_text || me.default.button_text,
      cancel_text: confirm_cancel_text || me.default.cancel_text
    }));

    $dialog
      .on('click', '#wDialog__confirm_button', function(){
        $dialog.remove();
        dfd.resolve(true);
      })
      .on('click', '#wDialog__cancel_button, .wDialog__close_btn', function(){
        $dialog.remove();
        dfd.resolve(false);
      });

    var $doc = $(document);
    $doc.on('keyup.shortcut', function(e){
      switch (e && e.keyCode) {
        case 13: // press enter
          $dialog.remove();
          dfd.resolve(true);
          $doc.off('keyup.shortcut');
          break;
        case 27: // press esc
          $dialog.remove();
          dfd.resolve(false);
          $doc.off('keyup.shortcut');
          break;
        default:
          break;
      }
    });

    $('body').append($dialog)
    return dfd.promise();
  }
};

window.wDialog = wDialog;
export default wDialog;
