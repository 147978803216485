var wNoti = {
  $container: null,
  $notiArry: [],

  show: function(msg, iconUrl, action) {
    var me = wNoti;
    action = action || {};

    if (!window.wProfile || !wProfile.profile.login) return;

    if (!me.$container) me.init();

    if ($('html').hasClass('mm-opened') || $(window).width() > 1280 && $('#side-nav').hasClass('mm-menu'))
      me.$container.addClass('mmopen');

    var data = {
      'link': action.url || "javascript:void",
      'icon': iconUrl || null,
      'type': action.type,
      'msgs': msg,
      'nid': me.$notiArry.length + 1
    };

    var $noti = $(require('../../templates/wnoti/msg')(data));
    $noti.appendTo(me.$container);
    me.$notiArry.push($noti);
    me.$container.addClass('show');
    if (window.ga) ga('send', 'event', 'website-tracking', 'popup-noti', 'show');

    setTimeout(function() { me.rmmsg(data.nid); }, 10000);
  },

  rmmsg: function(msgid) {
    var me = wNoti;
    var $noti = me.$notiArry[msgid-1];

    $noti.fadeOut(400, function() {
      $(this).remove();
      if (!me.$container.children().length)
        me.$container.removeClass('show');
    });
  },

  init: function() {
    var me = wNoti;

    me.$container = $("<section id='wNoti'>").appendTo('body');

    me.$container.on('click', '.msg', function(e) {
      var $this = $(this);
      switch ($this.data('type')) {
        case 'url':
          if (window.ga) ga('send', 'event', 'website-tracking', 'popup-noti', 'goto-msg-link');
          break;
        case 'close_msg':
          if (window.ga) ga('send', 'event', 'website-tracking', 'popup-noti', 'close-msg');
          $this.find('.closebtn').trigger("click");
          break;
        default:
          console.error('there must be some typo');
          break;
      }
    });
    me.$container.on('touchstart', '.msg', function() {
      me.rmmsg($(this).data('id'));
      return false;
    });
    me.$container.on('click', '.closebtn', function() {
      if (window.ga) ga('send', 'event', 'website-tracking', 'popup-noti', 'click-close-btn');
      me.rmmsg($(this).parents('.msg').data('id'));
      return false;
    });
  }
};

export default wNoti;
window.wNoti = wNoti;
