import '../vendors/jquery.tooltipster.min';
import tippy from 'tippy.js';

({
  $header: null,
  $body: null,
  $topShortcut: null,

  topShortcut: {
    pageWhitelist: ['b-articles'],
    subsiteWhitelist: ['s-womany', 's-gender', 's-family', 's-lianhonghong']
  },

  cachedEnv: {
    pageScrollY: undefined,
    lastTopShortcutToggleY: undefined
  },
  animationFrame: null,

  init: function(){
    var $header = this.$header = $('#w-header');
    if (!$header.length) return;

    this.$body = $('body');
    var $topShortcut = this.$topShortcut = $('#w-top-shortcut');

    this._initSearch();
    this._initCampaign();
    this._checkLogin();
    this._initTopShortcut();

    $header.find('.btn-feature').tooltipster({
      trigger: 'custom',
      triggerOpen: {
          mouseenter: true,
          tap: true
      },
      triggerClose: {
          mouseleave: true,
          originClick: true,
          touchleave: true
      }
    });

    // return to same page after login
    $header.find('.btn-login').attr('href', '/users/auth/womany?r=' + document.URL);

    // headerClickTracking
    $header.on('click', 'a', function(){
      if (window.ga) ga('send', 'event', 'website-tracking', 'menu-header_click', this.getAttribute('class'));
    });

    $('#submenu-account').on('click', 'a' , function() {
      if (window.ga) ga('send', 'event', 'website-tracking', 'menu-header_account-click', this.getAttribute('class'));
    });

    $topShortcut.on('click', 'a', function(){
      var str = this.getAttribute('data-trackThis') + '_' + (this.getAttribute('data-item-id') || this.getAttribute('title'));
      if (window.ga) ga('send', 'event', 'website-tracking', 'menu-shortcut_click', str);
    });
  },

  _checkLogin: function() {
    var me = this;

    (function waitForWprofile() {
      if (window.wProfile) {
        wProfile.ready(function(user_profile) {
          if (!user_profile.login) return;
          if (user_profile.womanyor) $('body').addClass('is-womanyor'); // fixme: move to wprofile.js
          me._checkEmailConfirm(user_profile);
          me._switchToLoginLayout(user_profile);
        });
      }
      else
        window.setTimeout(waitForWprofile, 1000);
    })();
  },

  _checkEmailConfirm: function(user_profile) {
    var me = this;
    var isInframe = me._checkInFrame();
    if (!user_profile.login || user_profile.confirmed || !user_profile.email || isInframe) return;

    var link = 'https://member.womany.net/users/confirmation/new?m=' + encodeURIComponent(user_profile.email);

    var $emailConfirm = $("<a class='users-email-confirm' href='" + link + "'>信箱驗證</a>")
    .insertAfter('#submenu-account .users-profile');
  },

  _checkInFrame: function() {
    var me = this;
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  },

  _switchToLoginLayout: function(user_profile) {
    var me = this;

    me.$header.find('.btn-login').remove();

    var $account = me.$header.find('.account');
    var tippyAccountMenu = null;

    $account.show().on('click', function() {
      if (!tippyAccountMenu) return true;

      if (!tippyAccountMenu.state.isShown)
        tippyAccountMenu.show();
      else
        tippyAccountMenu.hide();

      return false;
    });

    var tippyTheme = me.$body.hasClass('darkMode')? '' : 'light-border';

    tippyAccountMenu =
      tippy($account[0], {
        content: document.getElementById('submenu-account'),
        appendTo: () => document.body,
        allowHTML: true,
        interactive: true,
        placement: 'bottom',
        hideOnClick: 'toggle',
        theme: tippyTheme,

        onHide() {
          if (window.ga) ga('send', 'event', 'website-tracking', 'menu-header_account-hide');
        },
        onShow() {
          if (window.ga) ga('send', 'event', 'website-tracking', 'menu-header_account-show');
        }
      });

    $account.tooltipster('destroy');
    $account.css('background-image', 'url(' + user_profile.avatar + ')').children('i').remove();

    // for debugger
    // tippyAccountMenu.show();
    // window.tippyAccountMenu = tippyAccountMenu;
  },

  _initCampaign: function() {
    var me = this;
    var $campaignIcon = me.$header.find('.social').children('.campaign');
    var campaignNum = $campaignIcon.length;
    if (campaignNum == 0) return;

    if (campaignNum > 1)
      $campaignIcon = $campaignIcon.filter(':eq(' + Math.floor(Math.random() * campaignNum) + ')');

    $campaignIcon
      .removeClass('hide')
      .addClass($campaignIcon.attr('data-campaign'));
  },

  _initSearch: function() {
    var me = this;

    var $search = me.$header.find('.search');
    var $input = $search.find('.header-search-input');
    var $searchbtn = $search.children('a');

    $input
      .focus(function() {
        $input.addClass('show');
      })
      .blur(function() {
        var timeout = ($input.val().trim().length)? 2000 : 500;
        setTimeout(function() {
          $input.removeClass('show');
        }, timeout);
      });

    $searchbtn.on('touchstart click', function() {
      if (!$input.hasClass('show')) {
        $input.addClass('show');
        window.setTimeout(function() { $input.focus(); }, 760);
        return false;
      }

      var stext = $input.val().trim();
      if (!stext.length) stext = $input.attr('data-suggest');

      if (window.ga) ga('send', 'event', 'website-tracking', 'menu-header_click', 'search');
      window.open(womany.domain_url + '/search?q=' + encodeURIComponent(stext));

      return false;
    });

    $search.children('form').submit(function() {
      $searchbtn.click();
      return false;
    });

    $.getJSON(womany.api + '/keywords/recent').done(function(data) {
      if ((data.status != 'success') || (!data.data) || (!data.data.keywords) || (!data.data.keywords.length)) return;
      var kws = data.data.keywords;

      kws.sort(function(a, b) {
        return (b.articles_count - a.articles_count) * (Math.random() - 0.15) ;  // give it a 15% randomize
      });

      var kw = (kws[0] && kws[0].name);
      if (!kw) return;

      $input.attr('placeholder', '大家最愛搜 ' + kw).attr('data-suggest', kw);
    });
  },

  _initTopShortcut: function() {
    var me = this;
    var pageList = this.topShortcut.pageWhitelist;
    var subsiteList = this.topShortcut.subsiteWhitelist;
    // var pathname = window.location.pathname.split('?')[0];
    var $body = this.$body;

    // check if pagetype is allowed
    var onWhitelistSiteAndPage = function(){
      for (var subsite in subsiteList) {
        if ($body.hasClass(subsiteList[subsite])) {
          for (var page in pageList) {
            if ($body.hasClass(pageList[page])) return true;
          }
        }
      }
      return false;
    }();
    if (!onWhitelistSiteAndPage) return;

    $.getJSON(womany.api + '/nads/5d4c2d74e25bfbba')
      .done(function(data) {
        var items = data.items;
        if (!items || items.length == 0) {
          $body.removeClass('has-top-shortcut');
          return;
        }

        wUtil.arrayShuffle(items);

        var $topShortcut = me.$topShortcut;
        $topShortcut.append(require('../../templates/top-shortcut/shortcuts')(items));
        $body.addClass('has-top-shortcut');

        var $shortcutContain = $topShortcut.children('.w-shortcut-links');

        var $links = $shortcutContain.find('a[data-trackthis=shortcut]');
        var topShortcutWidth = $shortcutContain.width();
        var ADIds = items.map(function(item) { return item.item_id; });
        var postedADIds = [];
        var offsetRights = [];

        $links.each(function() {
          var $link = $(this);
          var offsetRight = $link.prop('offsetLeft') + $link.prop('offsetWidth');
          if (topShortcutWidth >= offsetRight) {
            postedADIds.push($link.attr('data-item-id'));
          }
          else {
            offsetRights.push(offsetRight);
          }
        });
        $.post(womany.api + '/nads/imps/' + postedADIds.join(','));

        function onTopShortcutScroll() {
          if(postedADIds.length == items.length) {
            $shortcutContain.off('scroll', onTopShortcutScroll);
            return;
          }
          var scrollRight = $shortcutContain.scrollLeft() + topShortcutWidth;

          for (var i = 0; i < offsetRights.length; i++) {
            if (scrollRight >= offsetRights[i]) {
              var id = ADIds[i + postedADIds.length];
              postedADIds.push(id);
              $.post(womany.api + '/nads/imps/' + id);
            }
            else {
              if (i>0) offsetRights.splice(0, i);
              break;
            }
          }
        }
        $shortcutContain.on('scroll', onTopShortcutScroll);

        if ($body.hasClass('b-home')) me._initShowHideTopShortcut();
      });
  },

  _initShowHideTopShortcut: function() {
    var me = this;
    var $body = me.$body;

    var debounced = function(){
      cancelAnimationFrame(me.animationFrame);

      me.animationFrame = requestAnimationFrame(function(){
        me._showHideTopShortcut();
      });
    };
    var debouncedWithPurge = function() {
      me.cachedEnv = {};
      debounced();
    };

    var $window = $(window);
    $window.on('scroll', debounced);
    $window.on('resize', debouncedWithPurge);
  },

  _showHideTopShortcut: function() {
    var me = this;
    var $topShortcut = me.$topShortcut;

    var pageY = me.cachedEnv.pageScrollY;
    var lastToggleY = me.cachedEnv.lastTopShortcutToggleY || 0;
    var scrollThreshold = 100;

    if (!pageY) {
      me.cachedEnv.pageScrollY = window.scrollY || 0;
      return;
    }
    var lastY = pageY;
    var currentY = window.scrollY;
    me.cachedEnv.pageScrollY = currentY;

    var dir = null;
    dir = (currentY > lastY)? 'down' : 'up';

    if (dir == 'down' && (currentY - lastToggleY > scrollThreshold)) {
      $topShortcut.addClass('hide');
      me.cachedEnv.lastTopShortcutToggleY = currentY;
    }
    if (dir == 'up'  && (currentY - lastToggleY < (scrollThreshold / 2))) {
      $topShortcut.removeClass('hide');
      me.cachedEnv.lastTopShortcutToggleY = currentY;
    }
  }
}).init();
