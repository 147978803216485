//  Aggregate vender libraries and js we need for all general layout pages

//  Vendor libraries

import Rails from 'rails-ujs';

//  Our own scripts

import './shared/header';
import './shared/sidenav';
import './shared/age_consent';
import wDialog from './shared/wDialog';
import './user_credit.js';

Rails.start();

$(function() {
  //login to continue
  $(document).on('click', '.js-login-first', function() {
    var title = $(this).data('title');
    var buttonText = $(this).data('button-text');
    wDialog.confirm('請先登入才可以' + title + '喔！', '登入', '登入', buttonText).done(function(res) {
      if (res) window.location = '/users/auth/womany';
    });
  });

  if (window.console) {
    try {
      var logStyle = "background: #464b4b;color:#FFF000;line-height:1.5em;";
      console.log("%c如果你有任何關於網站功能的建議、或是發現資安漏洞回報，歡迎來信 it@womany.net 告訴我們。", logStyle);
      console.log("%cIf you have any suggestions or found any security issues, please email us at it@womany.net", logStyle);
    }
    catch (error) {
      throw Error('console log styling error');
    }
  }
});
