/*
 * Store some useful js functions
 */

var wUtil = {
  pageCategory: function(){
    var title = '';
    var path = location.pathname;

    if (path.includes("collections")) {
      title += '特企';
    }
    else if (path.includes('read/article')) {
      title += '文章';
    }
    else if (path.includes('shop')) {
      if (path.includes('shop/item')) {
        title += '單一產品頁';
      }
      else if (path.includes('shop/campaigns')) {
        title += '商城活動頁';
      }
      else if (/shop$/.test(location.pathname)) {
        title += '商城首頁';
      }
      else {
        title += '商品品牌頁';
      }
    }
    else {
      title += '其他';
    }

    return title;
  },

  pageAnimateScrollTo: function(ele, intOffsetOrboolWithHeader) {
    // Scroll whole page to element (top)
    // intOffsetOrboolWithHeader: true = 60px; false = 0; or directly adjust offset (px)
    // default to set header + nav height as offset
    var topOffset = 60 + ($('body.has-top-shortcut').length ? 42 : 0);
    if (intOffsetOrboolWithHeader === false)
      topOffset = 0;
    if (Number.isInteger(intOffsetOrboolWithHeader))
      topOffset = intOffsetOrboolWithHeader;

    var dfd = new $.Deferred();
    var $ele = (ele instanceof jQuery)? ele : $(ele);
    if (!$ele.length) {
      dfd.reject();
      console.error('no elements were selected');
    }
    else {
      $('html, body').delay(300).animate({scrollTop: $ele.offset().top - topOffset}, 300).promise().done(function(){
        dfd.resolve();
      });
    }
    return dfd.promise();
  },

  /* Test data of `truncateString`
   * ('一二', 3) => '一二'
   * ('一二三', 3) => '一二三'
   * ('一二三四', 3) => '一二...'
   * ('一二abc', 3) => '一二...'
   * ('一a二b', 3) => '一a二b'
   * ('一a二bc', 3) => '一a...'
   */
  truncateString: function(str, maxlen){
    var substr = str.split('').reduce(function(prev, letter) {
      // 如果是英數字，消耗 0.5，否則消耗 1
      maxlen -= letter.match(/[a-zA-Z0-9]/) ? 0.5 : 1;
      // 保留一格給 `...`，如果有空位才加入到輸出結果
      return prev + (maxlen >= 1 ? letter : '');
    }, '');
    // 如果還有空位或剛好用完，用原本的，否則用簡化的
    return (maxlen < 0) ? substr + '…' : str;
  },

  numberToKString: function(pv){
    if (pv > 1000000){
      pv = (Math.floor(pv/100000)+'').replace(/(.$)/, '.$1M');
      return pv.replace('.0M', 'M');
    }
    if (pv > 1000){
      pv = (Math.floor(pv/100)+'').replace(/(.$)/, '.$1k');
      return pv.replace('.0k', 'k');
    }
    return pv+'';
  },

  linkFormSubmit: function(url, method){
    var method = method || 'POST';
    var csrfToken = $('meta[name=csrf-token]').attr('content'),
      csrfParam = $('meta[name=csrf-param]').attr('content'),
      form = $('<form method="post" action="' + url + '"></form>'),
      metadataInput = '<input name="_method" value="' + method + '" type="hidden" />';

    if (csrfParam !== undefined && csrfToken !== undefined) {
      metadataInput += '<input name="' + csrfParam + '" value="' + csrfToken + '" type="hidden" />';
    }

    form.hide().append(metadataInput).appendTo('body');
    form.submit();
  },

  arrayShuffle: function(array) {
    // http://stackoverflow.com/a/6274398
    var counter = array.length, temp, index;
    while (counter > 0) {
      index = Math.floor(Math.random() * counter);
      counter--;
      temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }
    return array;
  },

  arrayUniq: function(array) {
    return Array.from(new Set(array));
  },

  /*
    check if browser support `template string`
  */
  supportTemplateLiteral: function() {
    try{ return eval("''===``"); }
    catch(e){ return false; }
  },

  /*
    fetch user's location from cloudflare's un-official /cdn-cgi/trace
    there is no doc relative to it and we should consider it's non-stable
    return: TW, HK...etc
  */
  getUserLoc: function() {
    var dfd = new $.Deferred();

    try {
      $.get('https://womany.net/cdn-cgi/trace')
        .done(function(d){
          var data = d.replace(/[\r\n]+/g, '","').replace(/\=+/g, '":"');
          data = '{"' + data.slice(0, data.lastIndexOf('","')) + '"}';
          var jsondata = JSON.parse(data);
          dfd.resolve(jsondata.loc);
        })
        .fail(function(){
          dfd.resolve(undefined);
        });
    }
    catch (error) {
      dfd.resolve(undefined);
    }

    return dfd.promise();
  },

  /*
    return youtube video id url.

    @param {string} url
    @returns
  */
    getYtIDandList(url) {
    var info = {};

    // check if url already match youtube video id format
    if(/^(?:\w|-){11}$/.test(url)) {
      info.vid = url;
      return info;
    }

    var regex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((?:\w|-){11})(?:\S+)?$/;
    var result = regex.exec(url);
    if(!result) return false;
    info.vid = result[1];

    result = /(?:.list=)((?:\w|-){34})/.exec(url);
    if(result) info.list = result[1];

    return info;
  },

  /*
    get hash of string
    The hashing function returns a number between 0 and 4294967295 (inclusive)
    https://github.com/darkskyapp/string-hash
  */
  getStringHash: function(str) {
    var hash = 1234,
    i = str.length;
    while(i) {
      hash = (hash * 33) ^ str.charCodeAt(--i);
    }
    return hash >>> 0;
  }
};

window.wUtil = wUtil;
module.exports = wUtil;
