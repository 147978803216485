import '../vendors/mmenu/jquery.mmenu.min';
import docCookies from '../vendors/docCookies';
import tippy from 'tippy.js';

({
  // $window: null,
  $sideNav: null,
  mmenuAPI: null,

  init: function() {
    var me = this;
    // this.$window = $(window);
    var $sideNav = me.$sideNav = $('#side-nav');
    if (!$sideNav.length) return;

    this.initMmenu();

    $sideNav.removeClass("init-hide");

    this.triggerReadAndTalkCat();  // 自動切換至讀讀東西與討論區分類
    this.initClickTrack();
    this.initNewFeatureIndicator();
  },

  initMmenu: function(){
    var $sideNav = this.$sideNav;
    $sideNav.mmenu({
      extensions: ["theme-womany"],
      navbar: { title: '' }
    }, {
      offCanvas: {
        pageSelector: "#content-container",
        menuWrapperSelector: "div.body"
      }
    });

    var api = this.mmenuAPI = $sideNav.data('mmenu');

    $('#open-mmenu').on('click', function() {
      if ($sideNav.hasClass('mm-opened'))
        api.close();
      else
        api.open();
    });
  },

  triggerReadAndTalkCat: function(){
    var $body = $('body');
    var $sideNav = this.$sideNav;

    if (!$body.hasClass('s-womany')) return;

    if ($body.hasClass('b-interests') || $body.hasClass('b-articles'))
      this.mmenuAPI.openPanel($sideNav.find('.m-read-cat').parent());

    if ($body.hasClass('b-talk') || $body.hasClass('b-gender_violence') || $body.hasClass('b-singleplanet') || $body.hasClass('b-careerlife') || $body.hasClass('b-debates'))
      this.mmenuAPI.openPanel($sideNav.find('.m-debate-cat').parent());
  },

  initClickTrack: function(){
    var $sideNav = this.$sideNav;
    $sideNav.on('click', 'li', function(){
      if (!window.ga) return;

      var cstr = this.getAttribute('class') || '';
      cstr = cstr.match(/(m-\w*|i-\w*)/);
      if (!cstr || !cstr.length) return;
      ga('send', 'event', 'website-tracking', 'menu-side_nav_click', cstr[0]);
    });
  },

  initNewFeatureIndicator: function(){
    var now = new Date();

    this.$sideNav.find('li[data-start]').each(function() {
      var $link = $(this);
      var linkDate = new Date($link.attr('data-start'));
      var linkName = $link[0].className;
      var dateLimit = 86400 * 1000 * 14;

      if (!(linkDate instanceof Date)) return;
      if (now - linkDate > dateLimit) return;  // more then 30 days
      if (docCookies.getItem('sidenav_' + linkName)) return;  // already clicked

      $link.addClass('new-feature').on('click', function() {
        var Dt = new Date(Date.now() + dateLimit);
        docCookies.setItem('sidenav_' + linkName, true, Dt);
      });
    });
  }
}).init();
