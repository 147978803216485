var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.propertyIsEnumerable;

  return "          <div id=\"wDialog__cancel_button\" class=\"wDialog__button\">\n            "
    + container.escapeExpression(((helper = (helper = helpers.cancel_text || (depth0 != null ? depth0.cancel_text : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"cancel_text","hash":{},"data":data}) : helper)))
    + "\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.propertyIsEnumerable, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", alias5=container.escapeExpression;

  return "<div id=\"wDialog\">\n  <div class=\"wDialog__overlay\">\n    <div class=\"wDialog__wrapper\">\n      <div class=\"wDialog__header\">\n        <div class=\"wDialog__close_btn\">\n          <i class=\"fa fa-times\" aria-hidden=\"true\"></i>\n        </div>\n        <div class=\"wDialog__title\">\n          "
    + alias5(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n        </div>\n      </div>\n      <div class=\"wDialog__content\">\n        "
    + ((stack1 = ((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"text","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"wDialog__buttons\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.cancel_text : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div id=\"wDialog__confirm_button\" class=\"wDialog__button\">\n          "
    + alias5(((helper = (helper = helpers.button_text || (depth0 != null ? depth0.button_text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"button_text","hash":{},"data":data}) : helper)))
    + "\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});