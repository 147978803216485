import './shared/wNoti';

import image_w10   from '../images/wpoint/w10.png';
import image_w50   from '../images/wpoint/w50.png';
import image_w100  from '../images/wpoint/w100.png';
import image_w500  from '../images/wpoint/w500.png';
import image_w1500 from '../images/wpoint/w1500.png';

/*
 * This is script for point syncing/storage
 * look for points.js for WClub page script
 */

var wPoint = {
  lastPoint: null,
  syncTime: null,
  syncInterval: 7200000,  // 2hour interval
  curPoint: 0,
  actionRecord: {},
  arryRecord: {},
  ruleActionKey: null,
  html5storage: html5localStorage(),
  $pointDash: null,

  pointRule: {
    login: {
      point: 10,
      multiple: false,
      notiMsg: "每次登入女人迷，就能得到我們愛你10點囉",
      test: function() {
        var me = wPoint;
        var logRec = me.actionRecord.login || null;
        var now = new Date();

        if (!logRec || !logRec.timestamp){
          // If no record at all, set initial records
          me.memoAction('login', null, 0);
          $.post(womany.api + "/users/" + user_profile.id + "/login");
          return false;
        };

        if (!(now.getTime() > logRec.timestamp) || (now.getDate() == new Date(logRec.timestamp).getDate()))
          return false;

        $.post(womany.api + "/users/" + user_profile.id + "/login");
        me.memoAction('login', null, this.point);
        return true;
      }
    },
    read: {
      point: 10,
      multiple: true,
      notiMsg: "每天看文章，就能得到心靈充實點數！",
      restriction: 5,  // Daily
      itemid: function() {
        var id = document.location.pathname.match(/\/read\/article\/(\d+)/);
        if (!id) return null;
        return id[1];
      },
      test: function() {
        var me = wPoint;

        // is article?
        var aID = this.itemid();
        if (!aID) return false;

        // has read?
        var record = (me.arryRecord.read_article)? me.arryRecord.read_article : me.arryRecord.read_article = [];
        if (record.indexOf(aID) != -1) return false;
        record.push(aID);

        // over 5 today?
        if (me.actionRecord.read){
          var timeArray = Object.keys(me.actionRecord.read);
          var now = new Date();
          var startOfDay = (new Date(now.getFullYear(), now.getMonth(), now.getDate())).getTime();
          timeArray = timeArray.filter(function(ele) {
            return (ele * 1) > startOfDay;
          });
          if (timeArray.length > this.restriction) return false;
        };

        me.memoAction('read', aID, this.point);
        return true;
      }
    },
    comment: {
      point: 10,
      multiple: true,
      passive: true,  // Don't automatic check on page load
      notiMsg: "說說你的想法吧",
      restriction: 3,  // Daily
      test: function(comment_id) {
        var me = wPoint;

        // has comment id?
        var comment_id = comment_id || null;
        if (!comment_id) return false;

        // over 3 today?
        if (me.actionRecord.comment){
          var timeArray = Object.keys(me.actionRecord.comment);
          var now = new Date();
          var startOfDay = (new Date(now.getFullYear(), now.getMonth(), now.getDate())).getTime();
          timeArray = timeArray.filter(function(ele) {
            return (ele * 1) > startOfDay;
          });
          if (timeArray.length > this.restriction) return false;
        };

        me.memoAction('comment', comment_id, this.point);
        return true;
      }
    },
    subscribe: {
      point: 10,
      multiple: true,
      passive: true,  // Don't automatic check on page load
      notiMsg: "訂閱妳喜歡的，就能得到真心10點",
      test: function(id) {
        var me = wPoint;

        // has comment id?
        var id = id || null;
        if (!id) return false;

        var tmpArry = id.split('_');
        var id_type = tmpArry[0];
        var id_num = tmpArry[1];

        // has duplicate record?
        var record = (me.arryRecord['subscribe_' + id_type])? me.arryRecord['subscribe_' + id_type] : me.arryRecord['subscribe_' + id_type] = [];
        if (record.indexOf(id_num) != -1) return false;
        record.push(id_num);

        me.memoAction('subscribe', id, this.point);
        return true;
      }
    },
    heart: {
      point: 10,
      multiple: true,
      passive: true,  // Don't automatic check on page load
      notiMsg: "給認真的作者一點愛的鼓勵吧",
      test: function(id) {
        var me = wPoint;

        // articleID
        var id = id || null;
        if (!id) return false;

        // has duplicate record?
        var record = (me.arryRecord.heart_article)? me.arryRecord.heart_article : me.arryRecord.heart_article = [];
        if (record.indexOf(id) != -1) return false;
        record.push(id);

        if (me.actionRecord.heart){
          var dup = false;
          $.each(me.actionRecord.heart, function(i, item) {
            if (item.data == id) {
              dup = true;
              return false;
            };
          });
          if (dup) return false;
        };

        me.memoAction('heart', id, this.point);
        return true;
      },
    },
    experience: {
      point: 10,
      multiple: true,
      passive: true,  // Don't automatic check on page load
      notiMsg: "申請參加試用就能得到10點",
      test: function(id) {
        // won't be duplicate, so just ignore test
        wPoint.memoAction('experience', id, this.point);
        return true;
      }
    },
    expdone: {
      point: 100,
      multiple: true,
      passive: true,  // Don't automatic check on page load
      notiMsg: "完成試用獲得100點",
      test: function(id) {
        // won't be duplicate, so just ignore test
        wPoint.memoAction('expdone', id, this.point);
        return true;
      }
    },
    joinevent: {
      point: 50,
      multiple: true,
      passive: true,  // Don't automatic check on page load
      notiMsg: "參加活動就能獲得50點",
      test: function(id) {
        var me = wPoint;

        // has id?
        var id = id || null;
        if (!id) return false;

        var tmpArry = id.split('_');
        var id_type = tmpArry[0];
        var id_num = tmpArry[1];

        // has duplicate record?
        var record = (me.arryRecord['joinevent_' + id_type])? me.arryRecord['joinevent_' + id_type] : me.arryRecord['joinevent_' + id_type] = [];
        if (record.indexOf(id_num) != -1) return false;
        record.push(id_num);

        me.memoAction('joinevent', id, this.point);
        return true;
      }
    }
  },

  get: function(key, itemid) {
    var me = wPoint;
    var itemid = itemid || null;
    var ruleActionKey = me.ruleActionKey = me.ruleActionKey || Object.keys(me.pointRule);
    if (ruleActionKey.indexOf(key) < 0) return;

    if (me.pointRule[key].test(itemid)){
      me.curPoint += me.pointRule[key].point;
      me.showPointNoti(key);
      me.saveActionRecord();
    };
  },

  init: function() {
    var me = wPoint;

    if (!window.user_profile || !user_profile.login) return;

    if (me.html5storage){
      me.actionRecord = localStorage.getObject('w-actionRecord') || me.actionRecord;
      me.arryRecord = localStorage.getObject('w-arryRecord') || me.arryRecord;
    };

    me.syncPoint();
    me.aggregateReadArticles();
    me.calcCurPoint();

    for (var ruleKey in me.pointRule) {
      if (!me.pointRule[ruleKey].passive && me.pointRule[ruleKey].test()){
        me.curPoint += me.pointRule[ruleKey].point;
        me.showPointNoti(ruleKey);
      }
//      else {
//        if (window.console) console.log(ruleKey, 'not get');
//        me.showPointNoti(ruleKey);
//      }
    };

    me.calcCurPoint();
    me.saveActionRecord();
  },

  syncPoint: function() {
    var me = wPoint;

    me.lastPoint = user_profile.credits || 0;
    me.syncTime = user_profile.last_accessed_at * 1000 || 0;
  },

  aggregateReadArticles: function() {
    var me = wPoint;
    var arryRecord = me.arryRecord;

    var cat = { read: 'read_article', heart: 'heart_article' };
    for (var tp in cat){
      if (me.actionRecord[tp])
        for (var key in me.actionRecord[tp]){
          if (!arryRecord[cat[tp]]) arryRecord[cat[tp]] = [];
          arryRecord[cat[tp]].push(me.actionRecord[tp][key].data);
        };
    };

    if (me.actionRecord.subscribe){
      for (var times in me.actionRecord.subscribe){
        var data = me.actionRecord.subscribe[times].data.split('_');
        var cat = 'subscribe_' + data[0];
        if (!arryRecord[cat]) arryRecord[cat] = [];
        arryRecord[cat].push(data[1]);
      };
    };

    me.saveArryRecord();

    if ((new Date().getTime() - me.syncTime) > me.syncInterval){
      $.getJSON(womany.member_url + '/api/v1/users/' + user_profile.token + '/action_records').done(function(data) {
        if (!data) return;
        var me = wPoint;

        for (var tp in data) {
          if (Array.isArray(data[tp]) && data[tp].length){
            if (!me.arryRecord.hasOwnProperty(tp)) me.arryRecord[tp] = [];
            me.arryRecord[tp] = me.arryRecord[tp].concat(data[tp]);
          };
        };

        me.saveArryRecord();
      });
    };
  },

  saveArryRecord: function() {
    var arryRecord = wPoint.arryRecord;

    for (var key in arryRecord){
      var arryEach = arryRecord[key];
      var tmpArray = arryEach.filter(function(elem, pos) {
        return arryEach.indexOf(elem) == pos;
      });
      arryRecord[key] = tmpArray;
    };

    localStorage.setObject('w-arryRecord', arryRecord);
  },

  calcCurPoint: function() {
    var me = wPoint;
    me.curPoint = me.lastPoint;

    for (var ruleKey in me.pointRule) {
      if (me.pointRule[ruleKey].multiple) {
        for (var recTime in me.actionRecord[ruleKey]){
          if (recTime > me.syncTime)
            me.curPoint += me.actionRecord[ruleKey][recTime].point;
          else
            if (me.syncTime - recTime > 86400000)
              delete me.actionRecord[ruleKey][recTime];
        };
      }
      else {
        if (me.actionRecord[ruleKey] && me.actionRecord[ruleKey].timestamp && (me.actionRecord[ruleKey].timestamp > me.syncTime))
          me.curPoint += me.actionRecord[ruleKey].point;
      };
    };
  },

  showPointNoti: function(action) {
    var me = wPoint;
    var rule = wPoint.pointRule[action];
    if (window.console) console.log(rule.notiMsg, rule.point);

    // show no point noti anymore
    if (true) return;

    if (!window.wNoti) return;

    var imgUrl = (function() {
      switch (rule.point) {
        case 10:
          return image_w10;
        case 50:
          return image_w50;
        case 100:
          return image_w100;
        case 500:
          return image_w500;
        case 1500:
          return image_w1500;
        default:
          return null;
      };
      })();

    wNoti.show(rule.notiMsg, imgUrl, { type: 'close_msg' });
  },

  saveActionRecord: function() {
    localStorage.setObject('w-actionRecord', wPoint.actionRecord);
  },

  memoAction: function(action, itemid, score) {
    var me = wPoint;
    var rule = me.pointRule[action];
    var timestamp = Date.now();
    var score = score || 0;

    if (!rule.multiple){  // Action can be duplicate in actionRecord
      me.actionRecord[action] = {
        'timestamp': timestamp,
        'point': score
      };
      if (itemid) me.actionRecord[action].data = itemid;
    }
    else {
      if (!me.actionRecord[action]) me.actionRecord[action] = {};
      me.actionRecord[action][timestamp] = {
        'point': score
      };
      if (itemid) me.actionRecord[action][timestamp].data = itemid;
    };
  }
};

$(function() {
  wPoint.init();
});

window.wPoint = wPoint;
export default wPoint;

function html5localStorage() {
  try {
    return 'localStorage' in window && window['localStorage'] !== null;
  } catch (e) {
    return false;
  }
};

Storage.prototype.setObject = function(key, value) {
  this.setItem(key, JSON.stringify(value));
};
Storage.prototype.getObject = function(key) {
  var value = this.getItem(key);
  return value && JSON.parse(value);
};
