/*
 *  Favorite API wrapped into deferred object
 *
 *  data: {
 *    id: [id], check multiple ids with array
 *    type: Article (default) and others, check wFavorite.endPointMap
 *  }
 *
 *  get (get user's own fav/sub status):
 *    wFavorite.get({id: [id1, id2], type: favtype}).done(function(resp){
 *      // check resp.user_favorites.indexOf(id) > -1
 *    });
 *
 *  set (fav/sub an object):
 *    wFavorite.set({id: id, type: favtype}).done(function(){});
 *
 *  rm (un- fav/sub an object):
 *    wFavorite.rm({id: id, type: favtype}).done(function(){});
 *
 */


var wFavorite = {
  endPointMap: {
    'favorites': ['Article', 'DebateVote', 'EventComment', 'Product', 'ExperienceReview'],
    'subscriptions': ['Interest', 'Topic', 'User', 'Subsite']
  },

  getEndpoint: function(objType){
    var me = this;
    var ep = 'Article';
    $.each(me.endPointMap, function(key, val) {
      if (val.includes(objType)) ep = key;
    });
    return ep;
  },

  get: function(options){
    var me = this;
    var dfd = new $.Deferred();

    var options = $.extend({
      id: [],
      type: 'Article'
    }, options || {});

    if (!Array.isArray(options.id)) options.id = [options.id];

    var endPoint = me.getEndpoint(options.type);
    var dat = {
      method: 'POST',
      dataType: 'json',
      url: womany.api + '/' + endPoint,
      data: {
        type: options.type
      }
    };
    var ids = options.id.join(',');

    if ((endPoint == 'favorites') && (options.id.length == 1)) {
      // use catchable request endpoint
      dat.url += '/get';
      dat.method = 'GET';
      dat.data.id = ids;
    }
    else {
      dat.data.ids = ids;
    }

    if (window.wProfile && wProfile.profile.login)
      dat.xhrFields = { withCredentials: true };

    $.ajax(dat)
    .done(function(resp) {
      dfd.resolve(resp);
    })
    .fail(function( jqXHR, textStatus, errorThrown ) {
      dfd.rejectWith('ajax return error');
    });

    return dfd.promise();
  },

  set: function(options){
    var me = this;
    var dfd = new $.Deferred();

    var options = $.extend({
      id: null,
      type: 'Article'
    }, options || {});

    $.ajax({
      method: 'POST',
      dataType: 'json',
      xhrFields: { withCredentials: true },
      url: womany.api + '/'+ me.getEndpoint(options.type) +'/create',
      data: options
    })
    .then(function(resp) {
      if (window.ga) ga('send', 'event', 'website-tracking', 'wFavorite', me.getEndpoint(options.type) + '_add_' + options.type + '_' + options.id);
      if (resp.code == 'failure') dfd.reject();
      else dfd.resolve();
    })
    .catch(function( jqXHR, textStatus, errorThrown ) {
      dfd.reject();
    });

    return dfd.promise();
  },

  rm: function(options){
    var me = this;
    var dfd = new $.Deferred();

    var options = $.extend({
      id: null,
      type: 'Article'
    }, options || {});

    $.ajax({
      method: 'DELETE',
      dataType: 'json',
      xhrFields: { withCredentials: true },
      url: womany.api + '/' + me.getEndpoint(options.type) + '/destroy',
      data: options
    })
    .then(function(resp) {
      if (window.ga) ga('send', 'event', 'website-tracking', 'wFavorite', me.getEndpoint(options.type) + '_remove_' + options.type + '_' + options.id);
      if (resp.code == 'failure') dfd.reject();
      else dfd.resolve();
    })
    .catch(function( jqXHR, textStatus, errorThrown ) {
      dfd.reject();
    });

    return dfd.promise();
  }
};

window.wFavorite = wFavorite;

// export default wFavorite;
